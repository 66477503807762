<template>
	<div>
		<Loader v-if="keywordsLoad" />
		<div v-else class="mt-10 mt-5 container mx-auto">
			<div class="md:grid md:grid-cols-1 md:gap-6">
				<div class="md:col-span-1">
					<div class="px-4 sm:px-0">
						<h3 class="text-lg font-medium leading-6 text-gray-900">
							{{ $i18n("keyword.title") }} {{ method === "update" ? feed.name : "" }} -
							{{ $i18n(`action.${method}` ,'d') }}
						</h3>
					</div>
				</div>
				<div class="mt-5 md:mt-0 md:col-span-2">
					<form @submit.prevent="update">
						<div class="shadow overflow-hidden sm:rounded-md">
							<div class="px-4 py-5 bg-white sm:p-6">
								<div class="grid grid-cols-6 gap-6">
									<div class="col-span-6 sm:col-span-3">
										<label for="value" class="block text-sm font-medium text-gray-700">{{
											$i18n("misc.name")
										}}</label>
										<input
											v-model="keyword.value"
											type="text"
											name="value"
											id="value"
											autocomplete="given-name"
											class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
										/>
									</div>
								</div>
							</div>
							<div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
								<button
									@click="update"
									type="button"
									class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									{{ $i18n("action.save") }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
	name: "Feed",
	props: {
		id: {
			type: String,
		},
		method: {
			type: String,
		},
	},
	data() {
		return {
			keyword: {},
			error: false,
		};
	},
	components: {
		Loader,
	},
	computed: {
		...mapGetters("keywords", ["keywordsLoad"]),
	},
	methods: {
		...mapActions("keywords", ["createKeyword"]),
		async update() {
			try {
				if (this.method === "create") {
					await this.createKeyword(this.keyword);
				}
				this.$router.push({ path: "/keywords/" });
			} catch (error) {
				console.debug(error);
			}
		},
	},
	async created() {},
};
</script>
